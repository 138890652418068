import { Collapse, CollapseProps, Form, Input } from 'antd';
import { ContactGroupList } from 'features/Contact';
import { FormCommonError, FormForm, formRuleRequired } from 'features/Form';
import { MediaFileUpload, mediaAttachmentToUploadFile, mediaUploadFiles } from 'features/Media';
import { useCallback, useEffect, useMemo } from 'react';
import { IDirectorate, IDirectorateForm } from '../../Directorate.interface';

export function DirectorateBasicForm({ model, onFinish }: {
  model: IDirectorate;
  onFinish: (values: IDirectorate) => any;
}) {
  const [form] = Form.useForm<IDirectorateForm>();

  const initialValues = useMemo((): IDirectorateForm => {
    return {
      title: model.info.name,
      banner: mediaAttachmentToUploadFile(model.info.attachments),
      contacts: model.info.contact_groups,
    };
  }, [model]);

  const panelContent = useMemo((): CollapseProps['items'] => [
    {
      key: 1,
      label: 'Баннер',
      children: (
        <Form.Item
          className="mb-4"
          name="banner"
          rules={[formRuleRequired('Добавьте баннер')]}
          required
        >
          <MediaFileUpload maxCount={10} multiple />
        </Form.Item>
      ),
    },
    {
      key: 2,
      label: 'Контакты',
      children: (
        <ContactGroupList name="contacts" />
      ),
    },
  ], []);

  const handleFinish = useCallback(async (newValues: IDirectorateForm) => {
    const values = {
      ...initialValues,
      ...newValues,
    };

    const attachments = await mediaUploadFiles('directorate', values.banner, true);

    try {
      await onFinish({
        ...model,
        info: {
          ...model.info,
          name: values.title,
          contact_groups: values.contacts,
          attachments,
        },
      });
    } catch (e) {
      throw new FormCommonError();
    }
  }, [initialValues, model, onFinish]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
    >
      <Form.Item
        label="Название"
        name="title"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите название')]}
      >
        <Input />
      </Form.Item>

      <Collapse
        items={panelContent}
        defaultActiveKey={[]}
      />
    </FormForm>
  );
}
