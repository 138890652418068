import { ExportOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { APP_PUBLIC_URL } from 'App.environment';
import { createFormPanelList, createFormTextField, formFilter, formRuleRequired, IFormItemConfig } from 'features/Form';
import { createRichTextFormField } from 'features/RictText2';
import { ROLE_ADMIN_SUFFIX } from 'features/Role';
import type { ServiceType } from 'features/Service';
import { KeycloakRoles } from 'keycloak-js';
import { Link } from 'react-router-dom';
import { stringCompare } from 'utils/string';
import { createURL } from 'utils/url';
import { createCatalogEsrSelector, createCatalogExpressSelector } from '../Catalog';
import { createContactFormItem } from '../Contact';
import { canViewDirectorate, createDirectorateSelectorFormField } from '../Directorate/Directorate.model';
import { createMediaAttachFormField } from '../Media';
import { IStation } from './Station.interface';

export const STATION_FILE_PREFIX = 'station';

export enum StationTabTypes {
  BasicForm = 'basic',
  ServiceTable = 'service'
}

export function getStationShortName(station: IStation): string {
  return String(station?.info?.name_short || station?.info?.name);
}

export function stationSortByName(stationA: IStation, stationB: IStation) {
  return stringCompare(getStationShortName(stationA), getStationShortName(stationB));
}

export const stationTableColumnList: ColumnsType<IStation> = [
  {
    title: 'Вокзал',
    dataIndex: ['info', 'name'],
    render: (name, item) => {
      return (
        <Link to={`/stations/${item.slug}/${item.status}`}>
          {name || 'Без названия'}
        </Link>
      );
    },
  },
  {
    title: 'Город',
    dataIndex: ['info', 'city'],
  },
  {
    dataIndex: 'slug',
    render: (slug) => {
      return (
        <Link to={`${APP_PUBLIC_URL}/stations/${slug}`} target="_blank">
          <ExportOutlined />
        </Link>
      );
    },
  },
];

export const stationTableFilter = (search: string, item: IStation) => formFilter(search, item.info.name) || formFilter(search, String(item.esr_id));

export const stationEditUrl = (stationSlug: string, tab: StationTabTypes, serviceType: ServiceType) => createURL(`/stations/${stationSlug}/published`, {
  tab,
  type: serviceType,
});

export const stationServiceCreateUrl = (stationSlug: string, serviceType: ServiceType) => createURL(`/stations/${stationSlug}/${serviceType}/create`);
export const stationServiceEditUrl = (stationSlug: string, serviceSlug: string, serviceType: ServiceType) => createURL(`/stations/${stationSlug}/${serviceType}/${serviceSlug}`);

// roles

export const STATION_ROLE_PREFIX = 'roles_stations';

export function getStationRoleAdmin(slug: string) {
  return `${STATION_ROLE_PREFIX}_${slug}_${ROLE_ADMIN_SUFFIX}`;
}

export function canViewStationTable(roles: KeycloakRoles['roles']): boolean {
  return canViewDirectorate(roles) || roles.some((item) => item.startsWith(STATION_ROLE_PREFIX));
}

// form

export function stationFormItems(isDirectorateAdmin: boolean): IFormItemConfig[] {
  return [
    createFormTextField(['info', 'name'], 'Название вокзала', {
      rules: [formRuleRequired('Введите название вокзала')],
    }),
    createFormTextField(['info', 'name_short'], 'Короткое название', {
      className: 'w-1/3',
    }),
    createDirectorateSelectorFormField('directorate_slug', 'Подразделение', !isDirectorateAdmin),
    createFormTextField(['info', 'city'], 'Город', {
      rules: [formRuleRequired('Введите название города')],
      className: 'w-1/3',
    }),
    createRichTextFormField(['info', 'content'], 'О вокзале', STATION_FILE_PREFIX, {
      rules: [formRuleRequired('Добавьте описание')],
    }),
    createFormPanelList([
      {
        label: 'Настройки',
        children: [
          createCatalogExpressSelector('express_id', 'Express Id'),
          createFormTextField('longitude', 'Longitude', {
            rules: [formRuleRequired('Введите Longitude')],
            className: 'w-1/3',
          }),
          createFormTextField('latitude', 'Latitude', {
            rules: [formRuleRequired('Введите Latitude')],
            className: 'w-1/3',
          }),
        ],
      },
      {
        label: 'Баннер',
        children: [
          createMediaAttachFormField('attachments', 'Баннер', STATION_FILE_PREFIX, {
            rules: [formRuleRequired('Добавьте баннер')],
            required: true,
          }),
        ],
      },
      {
        label: 'Контакты',
        children: [
          createContactFormItem(['info', 'contacts'], 'Контакты'),
        ],
      },
      {
        label: 'Онлайн табло',
        children: [
          createCatalogEsrSelector('esr_id', 'ESR'),
        ],
      },
    ]),
  ];
}
