import { stringCompare } from './string';

export function arraySortBy<T>(field: keyof T) {
  return function (a: T, b: T) {
    return stringCompare(a?.[field] as string, b?.[field] as string);
  };
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export function toArray<T = any>(v: T | T[]): T[] {
  return Array.isArray(v) ? v : [v];
}
