import { Flex } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IFormItemConfig } from 'features/Form';
import { objectGet } from 'utils/object';
import { ModerateStatus } from './components/ModerateStatus/ModerateStatus';
import { IModerateable, ModerateStateTypes, ModerateStatusTypes } from './Moderate.interface';

export function isModerateStateDraftOrNew(state: ModerateStateTypes): boolean {
  return state === ModerateStateTypes.Draft || state === ModerateStateTypes.New;
}

export function isModerateStatusOnModeration(status: ModerateStatusTypes): boolean {
  return status === ModerateStatusTypes.NewOnModeration || status === ModerateStatusTypes.DraftOnModeration;
}

// --- table

export function createModerateColumns<T extends IModerateable>(columnList: ColumnsType<T>): ColumnsType<T> {
  return [
    ...columnList,
    {
      title: 'Статус',
      dataIndex: 'status',
      align: 'right',
      render: (status) => (
        <Flex gap={8} justify="end">
          <ModerateStatus status={status} />
        </Flex>
      ),
    },
    // {
    //   align: 'end',
    //   width: 64,
    //   render: (_, item) => <ModerateActionDropdown model={item} actions={actions} />,
    // },
  ];
}

export function getModerateState(status: ModerateStatusTypes): ModerateStateTypes {
  switch (status) {
    case ModerateStatusTypes.New:
      return ModerateStateTypes.New;
    case ModerateStatusTypes.Draft:
      return ModerateStateTypes.Draft;
    case ModerateStatusTypes.Published:
      return ModerateStateTypes.Published;
    case ModerateStatusTypes.NewOnModeration:
    case ModerateStatusTypes.DraftOnModeration:
      return ModerateStateTypes.Draft;
    default:
      return ModerateStateTypes.New;
  }
}

export function getModerateItemValue<T = any>(value: T, config: IFormItemConfig) {
  const v = objectGet(value, config.formItemProps.name);
  return config.parserModerate ? config.parserModerate(v, config) : v;
}
