import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDirectorateIsAdmin } from '../../../Directorate/Directorate.hook';
import { useStationCreateMutation, useStationInfoUpdateMutation } from '../../Station.api';
import { IStation } from '../../Station.interface';
import { stationFormItems } from '../../Station.model';
import { StationForm } from '../StationForm/StationForm';

export function StationCreate() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [stationCreate] = useStationCreateMutation();
  const [stationInfoUpdate] = useStationInfoUpdateMutation();
  const isDirectorateAdmin = useDirectorateIsAdmin();

  const handleFinish = useCallback(async (values: IStation) => {
    // create station
    const { info, ...base } = values;
    const result = await stationCreate({
      ...base,
      enabled: true,
    }).unwrap();

    // update info
    await stationInfoUpdate({
      ...info,
      slug: result.slug,
      lang: LANGUAGE_DEFAULT,
    }).unwrap();

    message.success('Вокзал создан');
    navigate(`/stations/${result.slug}/new`);
  }, [message, navigate, stationCreate, stationInfoUpdate]);

  return (
    <LayoutContent
      title="Создать вокзал"
      backUrl="/stations"
    >
      <StationForm onFinish={handleFinish} items={stationFormItems(isDirectorateAdmin)} />
    </LayoutContent>
  );
}
