import type { TagProps } from 'antd';
import type { IFormItemConfig } from 'features/Form';
import type { UseParamsBase } from 'features/Router';
import type { FC, MouseEventHandler } from 'react';
import type { IEntity } from 'utils/Entity.interface';

export enum ModerateStatusTypes {
  New = 'new',
  Draft = 'draft',
  Published = 'published',
  DraftOnModeration = 'draft_on_moderation',
  NewOnModeration = 'new_on_moderation',
}

export enum ModerateStateTypes {
  Draft = 'draft',
  Published = 'published',
  PublishedOnly = 'published_only',
  New = 'new',
}

export interface IModerateable extends IEntity {
  status: ModerateStatusTypes;
}

export interface IModerateTag {
  label: string;
  color: TagProps['color'];
}

// for form

export interface UseModerateParams extends UseParamsBase {
  status: ModerateStatusTypes;
}

export interface IModerateFormComponentProps<T> {
  model?: T;
  onFinish: IModerateOnFinishFunc<T>;
  onRemove?: MouseEventHandler<HTMLElement>;
  onRemoveDraft?: MouseEventHandler<HTMLElement>;
  items: IFormItemConfig[];
  status?: IModerateable['status'];
}

export interface IModerateFormModerateProps<T> {
  model?: T;
  status: ModerateStatusTypes;
  onRemove: MouseEventHandler<HTMLElement>;
  onFinish: IModerateOnFinishFunc<T>;
  items: IFormItemConfig[];
  component: FC<IModerateFormComponentProps<T>>;
  // moderation props
  onRemoveDraft: MouseEventHandler<HTMLElement>;
  onPrevLoad: Function;
  onApprove: MouseEventHandler<HTMLElement>;
  onDecline: MouseEventHandler<HTMLElement>;
}

export type IModerateOnFinishFunc<T> = (values: T, isStartModeration?: boolean) => any;

// for review

export interface IModerateDiffProps {
  next: string;
  prev?: string;
}

// for api

export interface IModerateListLoadParams {
  state: ModerateStateTypes;
}

export interface IModerateItemLoadParams extends IEntity {
  is_draft: boolean;
}
