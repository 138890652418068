import { Button } from 'antd';
import { LayoutContent } from 'features/Layout';
import { UITable, useNestedDataSource } from 'features/UI';
import { useDirectorateIsAdmin } from '../../../Directorate/Directorate.hook';
import { createModerateColumns, ModerateStateTypes } from '../../../Moderate';
import { useStationListWithRoles } from '../../Station.hooks';
import { IStation } from '../../Station.interface';
import { stationTableColumnList, stationTableFilter } from '../../Station.model';

export function StationTable() {
  const { data: published = [], isLoading: isPublishedLoading } = useStationListWithRoles(ModerateStateTypes.Published);
  const { data: draftOrNew = [], isLoading: isDraftLoading } = useStationListWithRoles(ModerateStateTypes.Draft);

  const isCreate = useDirectorateIsAdmin();

  const nestedData = useNestedDataSource<IStation>(draftOrNew, published);

  return (
    <LayoutContent title="Вокзалы">
      <UITable
        createButton={isCreate && (
          <Button href="/stations/create" className="grow-0">
            Создать вокзал
          </Button>
        )}
        filter={stationTableFilter}
        loading={isPublishedLoading || isDraftLoading}
        columns={createModerateColumns(stationTableColumnList)}
        dataSource={nestedData}
        pagination={false}
      />
    </LayoutContent>
  );
}
