import { Collapse, CollapseProps } from 'antd';
import { useMemo } from 'react';
import { IFormPanel } from '../../Form.interface';
import { FormItem } from '../FormItem/FormItem';

export function FormPanel({ items }: {
  items: IFormPanel[];
}) {
  const panelContent = useMemo((): CollapseProps['items'] => items.map((panel, index) => ({
    key: index,
    label: panel.label,
    children: panel.children.map((item, index2) => (
      <FormItem key={index2} model={item} />
    )),
  })), [items]);

  return (
    <Collapse items={panelContent} />
  );
}
