import { IModerateItemLoadParams, IModerateListLoadParams } from 'features/Moderate';
import { QueryMethodTypes } from 'features/Query';
import { IServiceBase, IServiceBaseInfo, IServiceBaseUpdateRequest, IServiceComplexInfoBaseUpdateRequest, ServiceType } from './Service.interface';
import { serviceBaseApi } from './ServiceBase.api';

const PREMIUM_LIST_TAG = {
  type: ServiceType.Premium,
  id: 'premiumList',
};

const servicePremiumApi = serviceBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // premium service list
    premiumListLoad: builder.query<IServiceBase[], IModerateListLoadParams>({
      query: ({ state }) => ({
        url: '/premium_services',
        params: { state },
      }),
      providesTags: (res = []) => [
        PREMIUM_LIST_TAG,
        ...res.map((item) => ({
          type: ServiceType.Premium,
          id: String(item.slug),
        })),
      ],
    }),

    // one premium
    premiumLoad: builder.query<IServiceBase, IModerateItemLoadParams>({
      query: ({ slug, is_draft }) => ({
        url: `/premium_services/${slug}`,
        params: { is_draft },
      }),
      providesTags: (res, error, req) => error ? [] : [{
        type: ServiceType.Premium,
        id: String(res.slug),
      }],
    }),
    premiumCreate: builder.mutation<IServiceBase, IServiceBaseUpdateRequest>({
      query: (body) => ({
        url: '/premium_services',
        method: QueryMethodTypes.POST,
        body,
      }),
    }),
    premiumInfoUpdate: builder.mutation<IServiceBaseInfo, IServiceComplexInfoBaseUpdateRequest>({
      query: ({ slug, lang, ...body }) => ({
        url: `/premium_services/${slug}/info/${lang}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [PREMIUM_LIST_TAG, {
          type: ServiceType.Premium,
          id: String(req.slug),
        }],
    }),
    premiumUpdate: builder.mutation<IServiceBase, IServiceBaseUpdateRequest>({
      query: ({ slug, ...body }) => ({
        url: `/premium_services/${slug}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [PREMIUM_LIST_TAG, {
          type: ServiceType.Premium,
          id: String(req.slug),
        }],
    }),
    premiumRemove: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/premium_services/${slug}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [PREMIUM_LIST_TAG, {
          type: ServiceType.Premium,
          id: String(req),
        }],
    }),

    // moderation
    premiumStartModeration: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/premium_services/${slug}/start_moderation`,
        method: QueryMethodTypes.PATCH,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [PREMIUM_LIST_TAG, {
          type: ServiceType.Premium,
          id: String(req),
        }],
    }),
    premiumApprove: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/premium_services/${slug}/approve`,
        method: QueryMethodTypes.PATCH,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [PREMIUM_LIST_TAG, {
          type: ServiceType.Premium,
          id: String(req),
        }],
    }),
    premiumDecline: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/premium_services/${slug}/decline`,
        method: QueryMethodTypes.PATCH,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [PREMIUM_LIST_TAG, {
          type: ServiceType.Premium,
          id: String(req),
        }],
    }),
    premiumRemoveDraft: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/draft_premium_services/${slug}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [PREMIUM_LIST_TAG, {
          type: ServiceType.Premium,
          id: String(req),
        }],
    }),
  }),
});


export const {
  // premium list
  usePremiumListLoadQuery,
  // premium one
  usePremiumLoadQuery,
  useLazyPremiumLoadQuery,
  usePremiumCreateMutation,
  usePremiumInfoUpdateMutation,
  usePremiumUpdateMutation,
  usePremiumRemoveMutation,
  // moderation
  usePremiumStartModerationMutation,
  usePremiumApproveMutation,
  usePremiumDeclineMutation,
  usePremiumRemoveDraftMutation,
} = servicePremiumApi;
