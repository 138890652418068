import { Form } from 'antd';
import { useMemo } from 'react';
import { IFormItemBuiltin, IFormItemConfig, IFormItemCustom } from '../../Form.interface';

export function FormItem({ model }: {
  model: IFormItemConfig;
}) {
  const Input = useMemo(() => (model as IFormItemBuiltin).input, [model]);
  const Component = useMemo(() => (model as IFormItemCustom).component || Form.Item, [model]);

  return (
    <Component {...model.formItemProps}>
      {Input && (
        <Input {...(model as IFormItemBuiltin).inputProps} />
      )}
    </Component>
  );
}
