import type { UploadFile } from 'antd';
import type { Dayjs } from 'dayjs';
import { LanguageTypes } from 'features/Language';
import { createURL } from 'utils/url';

export enum NewsTabTypes {
  News = 'news',
  Tags = 'tags',
}

export enum NewsRoleTypes {
  Admin = 'roles_news_admin',
}

export const newsTagTabUrl = createURL('/news', {
  tab: NewsTabTypes.Tags,
});

export interface INewsTag {
  id: number;
  title: string;
  lang: LanguageTypes;
}

export interface INews {
  attachments: string[];
  id: number;
  lang: LanguageTypes;
  tags: INewsTag[];
  content: string;
  title: string;
  finished_at: string;
  started_at: string;
}

// -- form

export interface INewsForm {
  title: string;
  banner: UploadFile[];
  content: string;
  tags: number[];
  time: [Dayjs, Dayjs];
}

export interface INewsTagForm {
  title: string;
}

// -- api

export interface NewsListLoadRequest {
  tag?: number;
  search_string?: string;
}

export interface NewsMutationBase {
  title: string;
  started_at: INews['started_at'];
  finished_at: INews['finished_at'];
  attachments?: string[];
  content: string;
  tags: number[];
}

export interface NewsUpdateRequest extends NewsMutationBase {
  id: number;
}

export interface NewsCreateRequest extends NewsMutationBase {
  lang: LanguageTypes;
}

export interface NewsTagBody {
  title: string;
}

export interface NewsTagCreateRequest extends NewsTagBody {
  lang: LanguageTypes;
}

export interface NewsTagUpdateRequest extends NewsTagBody {
  tag_id: number;
}
