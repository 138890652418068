import type { IModerateDiffProps } from 'features/Moderate';
import React from 'react';
import { Link } from 'react-router-dom';

function getHighlight<T = any>(next: T, prev?: T): string {
  if (!!prev && prev !== next) {
    return 'border-4 bg-green-300 border-green-300';

  }
  return 'border-4 border-white';
}

export function FormLinkDiff({ next, prev }: IModerateDiffProps) {
  if (!next) {
    return null;
  }

  return (
    <Link to={next} target="_blank" className={getHighlight(next, prev)}>
      {next}
    </Link>
  );
}
