import { Form, Input } from 'antd';
import { formRuleRequired } from 'features/Form';
import { MediaFileUpload } from 'features/Media';
import { PlateEditor } from 'features/RictText2';

export function PagePageFormBasic() {
  return (
    <div>
      <Form.Item
        label="Заголовок"
        name="title"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите заголовок')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="mb-4"
        label="Баннер"
        name="banner"
      >
        <MediaFileUpload />
      </Form.Item>

      <Form.Item
        label="Контент"
        name="content"
        rules={[formRuleRequired('Добавьте контент')]}
      >
        <PlateEditor />
      </Form.Item>
    </div>
  );
}
