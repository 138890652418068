import { FormItemTypes, IFormItemConfig, IFormSelectOption } from 'features/Form';
import type { NamePath } from 'rc-field-form/lib/interface';
import { ContactGroupList } from './components/ContactGroupList/ContactGroupList';
import { ContactBlock, ContactBlockTypes, ContactGroups } from './Contact.interface';

export const contactBlockOptions: IFormSelectOption[] = [
  {
    label: 'Текст',
    value: ContactBlockTypes.Text,
  },
  {
    label: 'Адрес',
    value: ContactBlockTypes.Address,
  },
  {
    label: 'E-mail',
    value: ContactBlockTypes.Email,
  },
  {
    label: 'Телефон',
    value: ContactBlockTypes.Phone,
  },
  {
    label: 'Ссылка',
    value: ContactBlockTypes.Link,
  },
];

export function contactParse(value: string): ContactGroups[] {
  if (!value) {
    return [];
  }
  try {
    return JSON.parse(value) || [];
  } catch (e) {
    console.error('--error parsing contacts', e);
    return [];
  }
}

function contactParseModerateContact(list: ContactBlock[]): string[] {
  return list.map((item) => [
    item.text,
    item.details,
  ]).flat();
}

function contactParseModerate(values: string): string {
  return contactParse(values)
    .map((item) => [
      item.title,
      item.sub_title,
      ...contactParseModerateContact(item.contacts),
      '-----',
    ])
    .flat()
    .join('\n');
}

export function contactSerialize(value: ContactGroups[]): string {
  try {
    return JSON.stringify(value);
  } catch (e) {
    console.error('--error serialize contacts', e);
    return '';
  }
}

export function createContactFormItem(name: NamePath, label: string): IFormItemConfig {
  return {
    type: FormItemTypes.ContactGroupList,
    component: ContactGroupList,
    parser: contactParse,
    parserModerate: contactParseModerate,
    serializer: contactSerialize,
    formItemProps: {
      name,
      label,
    },
  };
}
