import { RouteProps } from 'react-router-dom';
import { StationServiceCreate } from '../StationService/components/StationServiceCreate/StationServiceCreate';
import { StationCreate } from './components/StationCreate/StationCreate';
import { StationEdit } from './components/StationEdit/StationEdit';
import { StationStationServiceEdit } from './components/StationStationServiceEdit';
import { StationTable } from './components/StationTable/StationTable';

export const stationRouter: RouteProps[] = [
  {
    path: '/stations',
    Component: StationTable,
  },
  {
    path: '/stations/create',
    Component: StationCreate,
  },
  {
    path: '/stations/:slug/:status',
    Component: StationEdit,
  },
  {
    path: '/stations/:stationSlug/:serviceType/create',
    Component: StationServiceCreate,
  },
  {
    path: '/stations/:stationSlug/:serviceType/:serviceSlug',
    Component: StationStationServiceEdit,
  },
];
