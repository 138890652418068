import { FormItemTypes, IFormItemConfig } from 'features/Form';
import type { NamePath } from 'rc-field-form/lib/interface';
import { CatalogCategorySelector } from './components/CatalogCategorySelector/CatalogCategorySelector';
import { CatalogEsrSelector } from './components/CatalogEsrSelector/CatalogEsrSelector';
import { CatalogExpressSelector } from './components/CatalogExpressSelector/CatalogExpressSelector';

export function createCatalogSelectorFormField(name: NamePath, label: string): IFormItemConfig {
  return {
    type: FormItemTypes.CatalogSelector,
    component: CatalogCategorySelector,
    formItemProps: {
      name,
      label,
    },
  };
}

export function createCatalogExpressSelector(name: NamePath, label: string): IFormItemConfig {
  return {
    type: FormItemTypes.CatalogExpressSelector,
    component: CatalogExpressSelector,
    formItemProps: {
      name,
      label,
    },
  };
}

export function createCatalogEsrSelector(name: NamePath, label: string): IFormItemConfig {
  return {
    type: FormItemTypes.CatalogEsrSelector,
    component: CatalogEsrSelector,
    formItemProps: {
      name,
      label,
    },
  };
}
