import { Col, Row } from 'antd';
import { Diff } from 'features/Diff';
import { FormItemTypes, IFormItemBuiltin, IFormItemConfig } from 'features/Form';
import { useMemo } from 'react';
import { getModerateItemValue } from '../../Moderate.model';

function ModerateReviewItem({ next, prev, config, colSpan }: {
  next: any;
  prev?: any;
  config: IFormItemConfig;
  colSpan: number;
}) {
  const nextValue = useMemo(() => getModerateItemValue(next, config), [config, next]);
  const prevValue = useMemo(() => getModerateItemValue(prev, config), [config, prev]);
  const label = useMemo(() => config.type === FormItemTypes.CheckBox
    ? (config as IFormItemBuiltin).inputProps.children
    : config.formItemProps.label, [config]);

  const DiffComponent = useMemo(() => config.Diff || Diff, [config]);

  return (
    <>
      <Col span={4}>
        {label}
      </Col>
      {prev && (
        <Col span={colSpan}>
          <DiffComponent next={prevValue} />
        </Col>
      )}
      <Col span={colSpan}>
        <DiffComponent next={nextValue} prev={prevValue} />
      </Col>
    </>
  );
}

export function ModerateReview({ next, prev, items }: {
  next: any;
  prev?: any;
  items: IFormItemConfig[];
}) {
  const colSpan = useMemo(() => !!(prev) ? 10 : 20, [prev]);

  return (
    <div>
      {/* header */}
      <Row gutter={16} className="mb-6 border-b-2">
        <Col span={4}>Поле</Col>
        {prev && (
          <Col span={10}>Старое значение</Col>
        )}
        <Col span={10}>Новое значение</Col>
      </Row>

      {/* content */}
      {items.map((item, index) => (
        <Row gutter={16} key={index} className="mb-6">
          <ModerateReviewItem
            next={next}
            prev={prev}
            config={item}
            colSpan={colSpan}
          />
        </Row>
      ))}
    </div>
  );
}
