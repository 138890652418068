import { IModerateItemLoadParams, IModerateListLoadParams } from 'features/Moderate';
import { QueryMethodTypes } from 'features/Query';
import { IServiceBase, IServiceBaseInfo, IServiceBaseUpdateRequest, IServiceComplexInfoBaseUpdateRequest, ServiceType } from './Service.interface';
import { serviceBaseApi } from './ServiceBase.api';

const FLAGSHIP_LIST_TAG = {
  type: ServiceType.Flagship,
  id: 'flagshipList',
};

const serviceFlagshipApi = serviceBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // list
    flagshipListLoad: builder.query<IServiceBase[], IModerateListLoadParams>({
      query: ({ state }) => ({
        url: '/flagship_services',
        params: { state },
      }),
      providesTags: (res = []) => [
        FLAGSHIP_LIST_TAG,
        ...res.map((item) => ({
          type: ServiceType.Flagship,
          id: String(item.slug),
        })),
      ],
    }),
    // one
    flagshipLoad: builder.query<IServiceBase, IModerateItemLoadParams>({
      query: ({ slug, is_draft }) => ({
        url: `/flagship_services/${slug}`,
        params: { is_draft },
      }),
      providesTags: (res, error) => error ? [] : [{
        type: ServiceType.Flagship,
        id: String(res.slug),
      }],
    }),
    flagshipCreate: builder.mutation<IServiceBase, IServiceBaseUpdateRequest>({
      query: (body) => ({
        url: '/flagship_services',
        method: QueryMethodTypes.POST,
        body,
      }),
    }),
    flagshipUpdate: builder.mutation<IServiceBase, IServiceBaseUpdateRequest>({
      query: ({ slug, ...body }) => ({
        url: `/flagship_services/${slug}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [FLAGSHIP_LIST_TAG, {
          type: ServiceType.Flagship,
          id: String(req.slug),
        }],
    }),
    flagshipInfoUpdate: builder.mutation<IServiceBaseInfo, IServiceComplexInfoBaseUpdateRequest>({
      query: ({ slug, lang, ...body }) => ({
        url: `/flagship_services/${slug}/info/${lang}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [FLAGSHIP_LIST_TAG, {
          type: ServiceType.Flagship,
          id: String(req.slug),
        }],
    }),
    flagshipRemove: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/flagship_services/${slug}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [FLAGSHIP_LIST_TAG, {
          type: ServiceType.Flagship,
          id: String(req),
        }],
    }),

    // moderation
    flagshipStartModeration: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/flagship_services/${slug}/start_moderation`,
        method: QueryMethodTypes.PATCH,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [FLAGSHIP_LIST_TAG, {
          type: ServiceType.Flagship,
          id: String(req),
        }],
    }),
    flagshipApprove: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/flagship_services/${slug}/approve`,
        method: QueryMethodTypes.PATCH,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [FLAGSHIP_LIST_TAG, {
          type: ServiceType.Flagship,
          id: String(req),
        }],
    }),
    flagshipDecline: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/flagship_services/${slug}/decline`,
        method: QueryMethodTypes.PATCH,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [FLAGSHIP_LIST_TAG, {
          type: ServiceType.Flagship,
          id: String(req),
        }],
    }),
    flagshipRemoveDraft: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/draft_flagship_services/${slug}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [FLAGSHIP_LIST_TAG, {
          type: ServiceType.Flagship,
          id: String(req),
        }],
    }),
  }),
});

export const {
  // list
  useFlagshipListLoadQuery,
  // one
  useFlagshipLoadQuery,
  useLazyFlagshipLoadQuery,
  useFlagshipCreateMutation,
  useFlagshipUpdateMutation,
  useFlagshipInfoUpdateMutation,
  useFlagshipRemoveMutation,
  // moderation
  useFlagshipStartModerationMutation,
  useFlagshipApproveMutation,
  useFlagshipDeclineMutation,
  useFlagshipRemoveDraftMutation,
} = serviceFlagshipApi;
