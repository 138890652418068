import { Form, Input } from 'antd';
import { createFormList, FormListItemProps, formRuleRequired, formRuleUrl } from 'features/Form';
import { MediaFileUpload } from 'features/Media';
import { IPageBlockCarouselItem } from '../../Page.interface';
import { PAGE_BLOCK_CONTENT_FIELD } from '../../Page.model';

function CarouselGroupItem({ field }: FormListItemProps) {
  return (
    <div>
      <Form.Item
        className="mb-4"
        label="Картинка"
        name={[field.name, 'banner']}
        rules={[formRuleRequired('Добавьте картинку')]}
        required
      >
        <MediaFileUpload />
      </Form.Item>

      <Form.Item
        label="Заголовок"
        name={[field.name, 'title']}
        rules={[formRuleRequired('Добавьте заголовок')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Ссылка"
        name={[field.name, 'link']}
        rules={[formRuleUrl('Некорректная ссылка'), formRuleRequired('Добавьте ссылку')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Дополнительное описание"
        name={[field.name, 'description']}
      >
        <Input.TextArea />
      </Form.Item>
    </div>
  );
}

const createCarouselGroupList = () => createFormList<IPageBlockCarouselItem>(CarouselGroupItem, {
  addLabel: 'Добавить слайд',
  model: {
    banner: [],
    title: '',
    description: '',
    link: '',
  },
});

export function PageBlockFormCarousel() {
  return (
    <Form.List name={PAGE_BLOCK_CONTENT_FIELD}>
      {createCarouselGroupList()}
    </Form.List>
  );
}
