import { UploadFile } from 'antd';
import { FormItemTypes, IFormItemConfig } from 'features/Form';
import { NamePath } from 'rc-field-form/lib/interface';
import { promiseMap } from 'utils/promise';
import { MediaDiff } from './components/MediaDiff/MediaDiff';
import { MediaFileUpload } from './components/MediaFileUpload/MediaFileUpload';
import { clientMediaUpload } from './Media.client';
import { IMediaAttachment } from './Media.interface';

export const PUBLIC_MEDIA_HOST = String(process.env.REACT_APP_MEDIA);

export function getMediaUrl(src: string = ''): string {
  // TODO @dkchv: remove this after fix on BE
  if (String(src).startsWith('dzv.rzdsupport.ru')) {
    return getMediaUrl(src.replace('dzv.rzdsupport.ru', ''))
  }

  if (String(src).startsWith('/media')) {
    return `${PUBLIC_MEDIA_HOST}${src}`
  }
  return src;
}

function createMediaAttach(link: string = '', isAlt: boolean): IMediaAttachment {
  return isAlt
    ? {
      type: 'img',
      link,
      alt: '',
    }
    : {
      type: 'img',
      link,
    };
}

export function createUploadFile(url: string, index?: number | string): UploadFile {
  return {
    uid: String(index || url),
    name: url,
    url: getMediaUrl(url),
    status: 'done',
  };
}

export function mediaAttachmentToUploadFile(list: IMediaAttachment[]): UploadFile[] {
  return list.map((item, index) => createUploadFile(item.link, index));
}

export function mediaSingleToUploadFile(link?: string): UploadFile[] {
  if (!link) {
    return [];
  }
  return [createUploadFile(link)];
}

export function mediaArrayToUploadFile(list: string[]): UploadFile[] {
  return (list || []).map((item, index) => createUploadFile(item, index));
}

async function mediaUploadFile(prefix: string, file: File, isAlt: boolean): Promise<IMediaAttachment> {
  const link = await clientMediaUpload(prefix, file);
  return createMediaAttach(link, isAlt);
}

export class MediaUploadError extends Error {
  constructor() {
    super('Ошибка при загрузке изображений');
  }
}

export async function mediaUploadFileToString(prefix: string, file: UploadFile): Promise<string> {
  if (!file) {
    return '';
  }
  return file.url || await clientMediaUpload(prefix, file.originFileObj);
}

// TODO @dkchv: return string[] after fix on BE
export function mediaUploadFiles(prefix: string, files: UploadFile[], isAlt = false): Promise<IMediaAttachment[]> {
  if (!files) {
    return Promise.resolve([]);
  }
  try {
    return promiseMap(files, async (item): Promise<IMediaAttachment> => {
      if (!item.url) {
        return mediaUploadFile(prefix, item.originFileObj, isAlt);
      }
      return Promise.resolve(createMediaAttach(item.url, isAlt));
    });
  } catch (e) {
    throw new MediaUploadError();
  }
}

export async function mediaUploadFilesToStringArray(prefix: string, files: UploadFile[]): Promise<string[]> {
  const result = await mediaUploadFiles(prefix, files);
  return result.map((item) => item.link);
}

export function createMediaIconFormField(name: NamePath, label: string, prefix: string): IFormItemConfig {
  return {
    type: FormItemTypes.Image,
    input: MediaFileUpload,
    parser: mediaSingleToUploadFile,
    serializer: (value: UploadFile[]) => mediaUploadFileToString(prefix, value?.[0]),
    formItemProps: {
      name,
      label,
      className: 'mb-4',
    },
    inputProps: {
      accept: 'image/svg+xml',
    },
    Diff: MediaDiff,
  };
}

export function createMediaAttachFormField(name: NamePath, label: string, prefix: string, formItemsProps?: IFormItemConfig['formItemProps']): IFormItemConfig {
  return {
    type: FormItemTypes.Image,
    input: MediaFileUpload,
    parser: mediaArrayToUploadFile,
    serializer: (value: UploadFile[]) => mediaUploadFilesToStringArray(prefix, value),
    formItemProps: {
      name,
      label,
      className: 'mb-4',
      ...formItemsProps,
    },
    inputProps: {
      maxCount: 10,
      multiple: true,
    },
    Diff: MediaDiff,
  };
}
