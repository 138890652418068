import type { FormInstance, FormItemProps, FormListFieldData, FormListOperation, SelectProps } from 'antd';
import type { IModerateDiffProps } from 'features/Moderate';
import type { FC, ReactNode } from 'react';
import type { ArrayElement } from 'utils/array';

export type IFormSelectOption = ArrayElement<SelectProps['options']>;

export interface FormListItemProps<T = any> {
  field: FormListFieldData;
  form: FormInstance;
  meta: T;
}

export interface FormListMetaBase {
  onAdd: FormListOperation['add'];
  onRemove: FormListOperation['remove'];
}

export interface FormListOptions<T> {
  addLabel: string;
  addButton: ReactNode;
  isAdd: boolean;
  mode: FormListItemModeTypes;
  model: T;
  form: FormInstance;
  meta: FormListMetaBase & any;
}

export enum FormListItemModeTypes {
  Normal = 'normal',
  Inline = 'inline',
  Compact = 'compact',
}

export type onFormFinishFunc<T> = (values: T) => any;

// item

export enum FormItemTypes {
  // build-in
  Text = 'text',
  CheckBox = 'checkbox',
  Link = 'link',
  Panel = 'panel',
  Image = 'image',
  // custom
  RichText = 'richText',
  DirectorateSelector = 'directorateSelector',
  ContactGroupList = 'contactGroupList',
  CatalogSelector = 'catalogSelector',
  CatalogExpressSelector = 'catalogExpressSelector',
  CatalogEsrSelector = 'catalogEsrSelector',
}

interface IFormItemBase<M = any, F = any, P = any> {
  type: FormItemTypes;
  Diff?: FC<IModerateDiffProps>;
  parser?: (value: M, props: P) => F;
  serializer?: (value: F, props: P) => M;
  parserModerate?: (value: M, props: P) => string;
}

export interface IFormItemBuiltin<M = any, F = any, T = any, P = any> extends IFormItemBase<M, F, P> {
  formItemProps: FormItemProps<F>;
  inputProps?: T;
  input: FC<P>;
}

export interface IFormItemCustom<M = any, F = any, P = any> extends IFormItemBase<M, F, P> {
  formItemProps: P;
  component: FC<P>;
}

export type IFormItemConfig<M = any, F = any, T = any, P = any> = IFormItemBuiltin<M, F, T, P> | IFormItemCustom<M, F, P>

export interface IFormPanel {
  label: string;
  children: IFormItemConfig[];
}
