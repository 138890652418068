import { Button, TabsProps } from 'antd';
import { useAuthRoles } from 'features/Auth';
import { formFilter } from 'features/Form';
import { LayoutContent } from 'features/Layout';
import { createModerateColumns, ModerateStateTypes } from 'features/Moderate';
import { UITable, UITabs, useNestedDataSource } from 'features/UI';
import { useMemo } from 'react';
import { ISimple } from '../../../Simple/Simple.interface';
import { useServiceIsAdmin, useServiceListLoad } from '../../Service.hook';
import { IServiceBase, ServiceType } from '../../Service.interface';
import { canViewServiceTable, createServiceColumnList, serviceCRUDMap } from '../../Service.model';

const filter = (search: string, item: IServiceBase) => formFilter(search, item.info.title);

function ServiceTableByType({ serviceType }: {
  serviceType: ServiceType;
}) {
  const { data: draftOrNew = [], isLoading: isDraftLoading } = useServiceListLoad(serviceType, ModerateStateTypes.Draft);
  const { data: published = [], isLoading: isPublishedLoading } = useServiceListLoad(serviceType, ModerateStateTypes.Published);
  const isAdmin = useServiceIsAdmin(serviceType);

  const nestedData = useNestedDataSource<ISimple>(draftOrNew, published);

  return (
    <UITable
      createButton={isAdmin && (
        <Button href={`/services/${serviceType}/create`} className="mb-6">
          Создать услугу
        </Button>
      )}
      filter={filter}
      loading={isDraftLoading || isPublishedLoading}
      columns={createModerateColumns(createServiceColumnList(serviceType))}
      dataSource={nestedData}
      pagination={false}
    />
  );
}

export function ServiceTable() {
  const roles = useAuthRoles();

  const tabs = useMemo((): TabsProps['items'] => Object
    .values(ServiceType)
    .filter((item) => canViewServiceTable(roles, item))
    .map((item) => ({
      key: item,
      label: serviceCRUDMap[item].label,
      children: <ServiceTableByType serviceType={item} />,
    })), [roles]);

  return (
    <LayoutContent title="Услуги">
      <UITabs defaultActiveKey={ServiceType.Simple} items={tabs} />
    </LayoutContent>
  );
}
