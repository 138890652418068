import { Select, SelectProps } from 'antd';
import { formFilterOptionByLabel, IFormSelectOption } from 'features/Form';
import { ModerateStateTypes } from 'features/Moderate';
import { useMemo } from 'react';
import { useSimpleListLoadQuery } from '../../../Simple/Simple.api';
import { IStationService } from '../../StationService.intefrace';
import { useStationServiceSimpleListLoadQuery } from '../../StationServiceSimple.api';

export function StationServiceSimpleSelector({ stationService, ...props }: {
  stationService: IStationService;
} & SelectProps) {
  const { data: stationServiceSimpleList = [], isLoading: isStationServiceSimpleListLoading } = useStationServiceSimpleListLoadQuery(stationService.id);
  const { data: simpleAllList = [], isLoading: isSimpleAllLoading } = useSimpleListLoadQuery({
    state: ModerateStateTypes.Draft,
  });

  const options = useMemo(() => {
    if (isStationServiceSimpleListLoading || isSimpleAllLoading) {
      return [];
    }
    const idList = stationServiceSimpleList.map((item) => item.simple_service_id);

    return simpleAllList
      .filter((item) => !idList.includes(item.id))
      .map((item): IFormSelectOption => ({
        label: item.info.title,
        value: item.id,
      }));
  }, [isSimpleAllLoading, isStationServiceSimpleListLoading, simpleAllList, stationServiceSimpleList]);

  return (
    <Select
      options={options}
      filterOption={formFilterOptionByLabel}
      showSearch
      loading={isStationServiceSimpleListLoading || isSimpleAllLoading}
      {...props}
    />
  );
}
