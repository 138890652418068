import classnames from 'classnames';
import { useMemo } from 'react';

export function DiffValue({ next, prev }: {
  next: string;
  prev: string;
}) {
  const isDiff = useMemo(() => !!prev && next !== prev, [next, prev]);
  return (
    <div>
      {isDiff && (
        <div className="bg-red-200 line-through inline-block">{prev}</div>
      )}
      <div className={classnames('inline-block', {
        'bg-green-200': isDiff,
      })}>{next}</div>
    </div>
  );
}
