import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ONE_DAY_IN_MS } from 'utils/date';
import { IStation } from '../Station/Station.interface';
import { ITimeTableWithStation } from './TimeTable.interface';

export const timeTableColumnList: ColumnsType<ITimeTableWithStation> = [
  {
    title: 'ESR',
    dataIndex: 'esr_id',
  },
  {
    title: 'Вокзал',
    dataIndex: 'station',
    render: (item: IStation) => !!item
      ? <Link to={`/stations/${item.slug}/published`}>{item.info.name_short || item.info.name}</Link>
      : '---',
  },
  {
    title: 'Источник',
    dataIndex: 'source_type',
  },
  {
    title: 'Обновлено',
    dataIndex: 'updated_at',
    render: (item) => {
      const date = dayjs(item);
      const now = dayjs();
      const diff = now.diff(date);
      const isLate = diff / ONE_DAY_IN_MS > 24;

      return (
        <div className="flex gap-2 items-center">
          <span>{dayjs(item).format('YYYY-MM-DD HH:MM')}</span>
          {isLate && <div className="timetable_late" />}
        </div>
      );
    },
  },
];
