import type { UploadFile } from 'antd';
import type { ContactGroups } from 'features/Contact';
import { LanguageTypes } from 'features/Language';
import type { IMediaAttachment } from 'features/Media';
import type { UseParamsBase } from 'features/Router';

export enum DirectorateFormTabTypes {
  Basic = 'basic',
  Manager = 'manager',
}

export interface IDirectorateEditParams extends UseParamsBase {
  slug: IDirectorateManagerLoadRequest['slug'];
}

// -- directorate

export interface IDirectorate {
  slug: string;
  info: IDirectorateInfo;
}

export interface IDirectorateInfo {
  name: string;
  lang: LanguageTypes;
  contact_groups: ContactGroups[];
  attachments: IMediaAttachment[];
}

// for form

export interface IDirectorateForm {
  title: string;
  banner: UploadFile[];
  contacts: ContactGroups[];
}

// api

export interface IDirectorateUpdateRequest extends Partial<IDirectorateInfo> {
  slug: IDirectorate['slug'];
}

// -- manager

export interface IDirectorateManager {
  id: number;
  photo: string;
  info: IDirectorateManagerInfo;
}

export interface IDirectorateManagerInfo {
  lang: LanguageTypes;
  first_name: string;
  patronymic: string;
  last_name: string;
  position: string;
}

// form

export interface IDirectorateManagerEditParams extends IDirectorateEditParams {
  manager_id: IDirectorateManagerLoadRequest['manager_id'];
}

export interface IDirectorateManagerForm {
  firstName: IDirectorateManagerInfo['first_name'];
  lastName: IDirectorateManagerInfo['last_name'];
  patronymic: IDirectorateManagerInfo['patronymic'];
  position: IDirectorateManagerInfo['position'];
  image: UploadFile[];
}

// api

export interface IDirectorateManagerLoadRequest {
  slug: IDirectorate['slug'];
  manager_id: string;
}

export interface IDirectorateManagerBaseUpdateBody {
  photo: string;
}

export interface IDirectorateManagerCreateRequest extends IDirectorateManagerBaseUpdateBody {
  slug: IDirectorate['slug'];
}

export type IDirectorateManagerCreateResponse = Pick<IDirectorateManager, 'id' | 'info'>;

export interface IDirectorateManagerUpdateRequest extends IDirectorateManagerCreateRequest {
  manager_id: IDirectorateManager['id'];
}

export type IDirectorateManagerInfoUpdateBody = Pick<IDirectorateManagerInfo, 'first_name' | 'last_name' | 'patronymic' | 'position'>;

export interface IDirectorateManagerInfoUpdateRequest {
  slug: IDirectorate['slug'];
  lang: LanguageTypes;
  manager_id: IDirectorateManager['id'];
}

export interface IDirectorateManagerRemoveRequest {
  slug: IDirectorate['slug'];
  manager_id:IDirectorateManager['id'];
}

export interface IDirectorateManagerSortRequest {
  slug: IDirectorate['slug'];
  indexes: IDirectorateManager['id'][];
}
