import { useMemo } from 'react';
import { IEntity } from 'utils/Entity.interface';
import { TableNestedData } from './UITable.interface';

export function useNestedDataSource<T extends IEntity>(topList: T[], nestedList: T[]) {
  return useMemo(() => {
    // clone
    const result: TableNestedData<T>[] = topList.map((item: T) => ({ ...item }));

    // insert copies by slug
    nestedList.forEach((item: T) => {
      const parent = result.find((i2: T) => i2.slug === item.slug);
      if (!parent) {
        result.push(item);
      } else {
        const children = parent.children || [];
        children.push(item);
        parent.children = children;
      }
    });
    return result;
  }, [topList, nestedList]);
}
