import { LayoutContent } from 'features/Layout';
import { UITable } from 'features/UI';
import { useMemo } from 'react';
import { useStationListLoad } from '../../../Station/Station.api';
import { stationTableFilter } from '../../../Station/Station.model';
import { useTimeTableListLoadQuery } from '../../TimeTable.api';
import { ITimeTableWithStation } from '../../TimeTable.interface';
import { timeTableColumnList } from '../../TimeTable.model';

const filter = (search: string, item: ITimeTableWithStation) => item.station && stationTableFilter(search, item.station);

export function TimeTableTable() {
  const { data = [], isLoading } = useTimeTableListLoadQuery();
  const { data: stationList = [], isLoading: isStationLoading } = useStationListLoad();

  const fixed = useMemo(() => {
    if (isLoading || isStationLoading) {
      return [];
    }
    return data.map((time): ITimeTableWithStation => ({
      ...time,
      station: stationList.find((item) => item.esr_id === time.esr_id),
    }));
  }, [data, isLoading, isStationLoading, stationList]);

  return (
    <LayoutContent title="Онлайн табло">
      <UITable
        filter={filter}
        loading={isLoading || isStationLoading}
        dataSource={fixed}
        columns={timeTableColumnList}
        className="pb-6"
        pagination={false}
      />
    </LayoutContent>
  );
}
