import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { ModerateForm, useModerateOnFinish } from 'features/Moderate';
import { ServiceType } from 'features/Service';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDirectorateIsAdmin } from '../../../Directorate/Directorate.hook';
import { useStationServiceListLoadQuery, useStationServiceRemoveMutation } from '../../../StationService/StationService.api';
import { useLazyStationLoadQuery, useStationApproveMutation, useStationDeclineMutation, useStationInfoUpdateMutation, useStationLoad, useStationRemoveDraftMutation, useStationRemoveMutation, useStationStartModerationMutation, useStationUpdateMutation } from '../../Station.api';
import { IStation, IStationEditFormParams } from '../../Station.interface';
import { stationFormItems } from '../../Station.model';
import { StationForm } from '../StationForm/StationForm';

export function StationEdit() {
  const { slug, status } = useParams<IStationEditFormParams>();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { data, isLoading } = useStationLoad(slug, status);
  const isDirectorateAdmin = useDirectorateIsAdmin(data?.directorate_slug);
  const {
    data: stationServiceList = [],
    isLoading: isStationServiceListLoading,
  } = useStationServiceListLoadQuery({
    station_slug: slug,
    service_type: ServiceType.Simple,
  });
  const [stationUpdate] = useStationUpdateMutation();
  const [stationRemove] = useStationRemoveMutation();
  const [stationRemoveDraft] = useStationRemoveDraftMutation();
  const [stationInfoUpdate] = useStationInfoUpdateMutation();
  const [stationServiceRemove] = useStationServiceRemoveMutation();
  const [stationStartModeration] = useStationStartModerationMutation();
  const [stationApprove] = useStationApproveMutation();
  const [stationDecline] = useStationDeclineMutation();
  const [stationPrevLoad] = useLazyStationLoadQuery();
  const backUrl = useMemo(() => '/stations', []);

  const handleFinish = useCallback(async (values: IStation) => {
    const { info, ...base } = values;

    await stationUpdate(base).unwrap();
    await stationInfoUpdate({
      slug,
      ...info,
    }).unwrap();
  }, [slug, stationInfoUpdate, stationUpdate]);

  const handleRemove = useCallback(async () => {
    // remove related services
    const promises = stationServiceList.map((item) => stationServiceRemove(item.id).unwrap());
    await Promise.all(promises);
    // remove station
    await stationRemove(data.slug).unwrap();
    navigate(backUrl);
  }, [stationServiceList, stationRemove, data, navigate, backUrl, stationServiceRemove]);

  // moderation

  const handleOnStartModeration = useCallback(() => {
    return stationStartModeration(data.slug).unwrap();
  }, [data, stationStartModeration]);

  const handleModerateFinish = useModerateOnFinish(handleFinish, handleOnStartModeration, backUrl, 'Вокзал обновлен');

  const handleRemoveDraft = useCallback(async () => {
    await stationRemoveDraft(data.slug).unwrap();
    message.success('Черновик удален');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, stationRemoveDraft]);

  const handleApprove = useCallback(async () => {
    await stationApprove(data.slug).unwrap();
    message.success('Изменения приняты');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, stationApprove]);

  const handleDecline = useCallback(async () => {
    await stationDecline(data.slug).unwrap();
    message.success('Изменения отклонены');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, stationDecline]);

  const handlePrevLoad = useCallback(() => {
    return stationPrevLoad({
      slug,
      is_draft: false,
    });
  }, [slug, stationPrevLoad]);

  return (
    <LayoutContent
      title="Редактирование вокзала"
      backUrl="/stations"
      isLoading={isLoading || isStationServiceListLoading}
      render={() => (
        <ModerateForm
          status={status}
          model={data}
          items={stationFormItems(isDirectorateAdmin)}
          component={StationForm}
          onFinish={handleModerateFinish}
          onRemove={handleRemove}
          onRemoveDraft={handleRemoveDraft}
          onApprove={handleApprove}
          onDecline={handleDecline}
          onPrevLoad={handlePrevLoad}
        />
      )}
    >
    </LayoutContent>
  );
}
