import { Select, SelectProps } from 'antd';
import { DiffValue } from 'features/Diff';
import { formFilterOptionByLabel, IFormSelectOption } from 'features/Form';
import type { IModerateDiffProps } from 'features/Moderate';
import { Spinner } from 'features/UI';
import { useMemo } from 'react';
import { useDirectorateListLoadQuery } from '../../Directorate.api';
import { useDirectorateRoles } from '../../Directorate.hook';
import { getDirectorateName, isDirectorateAdmin } from '../../Directorate.model';

export function DirectorateSelector(props: SelectProps) {
  const { data = [], isLoading } = useDirectorateListLoadQuery();
  const roles = useDirectorateRoles();

  const options = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return data.map((item): IFormSelectOption => ({
      label: item.info.name,
      value: item.slug,
      disabled: !isDirectorateAdmin(roles, item.slug),
    }));
  }, [isLoading, data, roles]);

  return (
    <Select
      options={options}
      filterOption={formFilterOptionByLabel}
      showSearch
      loading={isLoading}
      {...props}
    />
  );
}

export function DirectorateSelectorDiff({ next, prev }: IModerateDiffProps) {
  const { data = [], isLoading } = useDirectorateListLoadQuery();
  const [nextValue, prevValue] = useMemo((): [string, string] => isLoading
    ? ['', '']
    : [getDirectorateName(data, next), getDirectorateName(data, prev)], [data, isLoading, next, prev]);

  if (isLoading) {
    return <Spinner />;
  }

  return <DiffValue next={nextValue} prev={prevValue} />;
}
