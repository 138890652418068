import { ExportOutlined, LockTwoTone } from '@ant-design/icons';
import { Collapse, CollapseProps, Form, Input, Space, Typography } from 'antd';
import { ContactGroupList } from 'features/Contact';
import { FormCommonError, FormForm, formRuleRequired, formRuleUrl } from 'features/Form';
import { MediaFileUpload, mediaArrayToUploadFile, mediaUploadFiles } from 'features/Media';
import { ModerateStatusTypes } from 'features/Moderate';
import { PlateEditor, richText2SerializeContent } from 'features/RictText2';
import { IServiceBase, ServiceType } from 'features/Service';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { prefixUrl } from 'utils/url';
import { contactParse } from '../../../Contact/Contact.model';
import { ServiceSelector } from '../../../Service/components/ServiceSelector/ServiceSelector';
import { StationSelector } from '../../../Station/components/StationSelector/StationSelector';
import { IStation } from '../../../Station/Station.interface';
import { IStationService, IStationServiceForm, stationServiceFormEmpty } from '../../StationService.intefrace';

export function StationServiceBasicForm({ model, station, service, serviceType, serviceStatus, onRemove, onFinish }: {
  model?: IStationService;
  station?: IStation;
  service?: IServiceBase;
  serviceType: ServiceType;
  serviceStatus: ModerateStatusTypes;
  onFinish: (values: IStationServiceForm, attachments: string[]) => any;
  onRemove?: (id: number) => any;
}) {
  const [form] = Form.useForm<IStationServiceForm>();

  const initialValues = useMemo((): IStationServiceForm => {
    if (!model) {
      return {
        ...stationServiceFormEmpty,
        stationId: station?.id,
        serviceId: service?.id,
        banner: [],
      };
    }

    return {
      title: model.info.title,
      content: model.info.content,
      stationId: model.station_id,
      serviceId: model.service_id,
      externalLink: model.external_link,
      contacts: contactParse(model.info.contacts),
      banner: mediaArrayToUploadFile(model.attachments || []),
    };
  }, [model, service, station]);

  const handleFinish = useCallback(async (newValues: IStationServiceForm) => {
    const values = {
      ...initialValues,
      ...newValues,
    };
    const [content, attachments] = await Promise.all([
      richText2SerializeContent('station-service', values.content),
      mediaUploadFiles('station-service', values.banner),
    ]);

    try {
      await onFinish({
        ...values,
        externalLink: prefixUrl(values.externalLink),
        content,
      }, attachments.map((item) => item.link));
    } catch (e) {
      console.error('form error', e);
      throw new FormCommonError();
    }
  }, [initialValues, onFinish]);

  const handleRemove = useCallback(() => {
    return onRemove?.(model.id);
  }, [model, onRemove]);

  const panelContent = useMemo((): CollapseProps['items'] => [
    {
      key: 'contacts',
      label: 'Контакты',
      children: (
        <ContactGroupList name="contacts" />
      ),
    },
  ], []);

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      onRemove={model && handleRemove}
    >
      {service
        ? (
          <div className="mb-6">
            <Space>
              <Typography.Text>Услуга:</Typography.Text>
              <Typography.Text italic>
                {service.info.title}
              </Typography.Text>
              <LockTwoTone />
              <Link to={`/services/${serviceType}/${service.slug}/${serviceStatus}`}>
                <ExportOutlined />
              </Link>
            </Space>
          </div>
        )
        : (
          <Form.Item
            label="Услуга"
            name="serviceId"
            rules={[formRuleRequired('Выберите услугу')]}
            validateTrigger="onBlur"
          >
            <ServiceSelector serviceType={serviceType} station={station} disabled={!!model} />
          </Form.Item>
        )}

      {station
        ? (
          <div className="mb-6">
            <Space>
              <Typography.Text>Вокзал:</Typography.Text>
              <Typography.Text italic>
                {station.info.name}
              </Typography.Text>
              <LockTwoTone />
              <Link to={`/stations/${station.slug}/published`}>
                <ExportOutlined />
              </Link>
            </Space>
          </div>
        )
        : (
          <Form.Item
            label="Вокзал"
            name="stationId"
            rules={[formRuleRequired('Выберите вокзал')]}
            validateTrigger="onBlur"
          >
            <StationSelector disabled={!!model} service={service} serviceType={serviceType} />
          </Form.Item>
        )}

      {serviceType !== ServiceType.Simple && (
        <Form.Item
          label="Название"
          name="title"
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item
        label="Внешняя ссылка"
        name="externalLink"
        className="w-2/3"
        rules={[formRuleUrl('Введите корректную ссылку')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="mb-4"
        label="Баннер"
        name="banner"
      >
        <MediaFileUpload maxCount={10} multiple />
      </Form.Item>

      <Form.Item
        label="Описание"
        name="content"
      >
        <PlateEditor />
      </Form.Item>

      <Collapse
        items={panelContent}
        defaultActiveKey={model ? [] : ['contacts']}
      />
    </FormForm>
  );
}
