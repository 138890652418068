import { App } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal/interface';
import { useCallback } from 'react';

export function useUIConfirm(title: string, callback: ModalFuncProps['onOk']) {
  const { modal, message } = App.useApp();

  return useCallback(() => {
    modal.confirm({
      title,
      onOk: async () => {
        try {
          await callback();
        } catch (e) {
          console.error(e);
          message.error(e.message || 'Ошибка');
        }
      },
      okText: 'Да',
      cancelText: 'Нет',
    });
  }, [callback, message, modal, title]);
}
