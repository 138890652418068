import { Image } from 'antd';
import type { IModerateDiffProps } from 'features/Moderate';
import React, { useMemo } from 'react';
import { toArray } from 'utils/array';
import { getMediaUrl } from '../../Media.model';

function getHighlight<T = any>(next: T, prev?: T): string {
  if (!!prev && prev !== next) {
    return 'border-4 bg-green-300 border-green-300';

  }
  return 'border-4 border-white';
}

export function MediaDiff({ next, prev }: IModerateDiffProps) {
  const nextArr = useMemo(() => toArray(next), [next]);
  const prevArr = useMemo(() => toArray(prev), [prev]);

  if (!next) {
    return null;
  }

  return nextArr.map((item, index) => (
    <Image
      key={index}
      src={getMediaUrl(item)}
      preview={false}
      className={getHighlight(item, prevArr[index])}
    />
  ));
}
