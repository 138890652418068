import { DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { FormCommonError, FormForm, formRuleRequired, IFormSelectOption } from 'features/Form';
import { mediaArrayToUploadFile, MediaFileUpload, mediaUploadFilesToStringArray } from 'features/Media';
import { PlateEditor, richText2SerializeContent } from 'features/RictText2';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNewsRemoveMutation, useNewsTagListLoadQuery } from '../../News.api';
import { INews, INewsForm, NewsMutationBase } from '../../News.interfaces';

export function NewsForm({ model, onFinish }: {
  model?: INews;
  onFinish: (values: NewsMutationBase) => any;
}) {
  const navigate = useNavigate();
  const [form] = Form.useForm<INewsForm>();
  const { data: tagList = [], isFetching: isTagListFetching } = useNewsTagListLoadQuery();
  const [newsRemove] = useNewsRemoveMutation();

  // parse values
  const initialValues = useMemo((): INewsForm => {
    if (!model) {
      return {
        title: '',
        banner: [],
        content: '',
        tags: [],
        time: [dayjs(), null],
      };
    }

    const time: INewsForm['time'] = [
      model.started_at ? dayjs(model.started_at) : null,
      model.finished_at ? dayjs(model.finished_at) : null,
    ];

    return {
      title: model.title,
      banner: mediaArrayToUploadFile(model.attachments),
      content: model.content,
      tags: model.tags?.map((item) => item.id) || [],
      time,
    };
  }, [model]);

  const tagOptions = useMemo(() => {
    return tagList.map((item): IFormSelectOption => ({
      label: item.title,
      value: item.id,
    }));
  }, [tagList]);

  const handleRemove = useCallback(async () => {
    await newsRemove(model.id).unwrap();
    navigate('/news');
  }, [model, navigate, newsRemove]);

  const handleFinish = useCallback(async (values: INewsForm) => {
    const [attachments, content] = await Promise.all([
      mediaUploadFilesToStringArray('news', values.banner),
      richText2SerializeContent('news', values.content),
    ]);

    try {
      const payload: NewsMutationBase = {
        title: values.title,
        content,
        tags: values.tags,
        attachments,
        started_at: values.time[0] ? values.time[0].format() : null,
        finished_at: values.time[1] ? values.time[1].format() : null,
      };
      await onFinish(payload);
    } catch (e) {
      throw new FormCommonError();
    }
  }, [onFinish]);

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      onRemove={model && handleRemove}
    >
      <Form.Item
        label="Заголовок"
        name="title"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите заголовок')]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        className="mb-4"
        label="Баннер"
        name="banner"
        rules={[formRuleRequired('Добавьте баннер')]}
        required
      >
        <MediaFileUpload />
      </Form.Item>

      <Form.Item
        label="Содержание"
        name="content"
        rules={[formRuleRequired('Добавьте содержание')]}
      >
        <PlateEditor />
      </Form.Item>

      <Form.Item label="Тэги" name="tags">
        <Select
          mode="multiple"
          allowClear
          options={tagOptions}
          loading={isTagListFetching}
        />
      </Form.Item>

      <Form.Item label="Дата и время публикации" name="time">
        <DatePicker.RangePicker
          showTime
          format="YYYY-MM-DD HH:mm"
          allowEmpty={[true, true]}
        />
      </Form.Item>
    </FormForm>
  );
}
