import { Form, Input } from 'antd';
import { featureFlags } from 'App.environment';
import { formRuleRequired, formRuleUrl } from 'features/Form';
import { MediaFileUpload } from 'features/Media';
import { IPageBlockEditFormProps } from '../../Page.interface';
import { PAGE_BLOCK_LINK_NONE } from '../../Page.model';

export function PageBlockFormImage({ model }: IPageBlockEditFormProps) {
  return (
    <div>
      <Form.Item
        className="mb-4"
        label="Картинка"
        name="banner"
        rules={[formRuleRequired('Добавьте картинку')]}
        required
      >
        <MediaFileUpload />
      </Form.Item>

      {(featureFlags.isDev || model.link !== PAGE_BLOCK_LINK_NONE) && (
        <Form.Item
          label="Дополнительная ссылка"
          name="link"
          rules={!featureFlags.isDev ? [formRuleUrl('Некорректная ссылка')] : []}
        >
          <Input />
        </Form.Item>
      )}

    </div>
  );
}
