import { TabsProps } from 'antd';
import { useAuthRoles } from 'features/Auth';
import { IModerateFormComponentProps, ModerateStatusTypes } from 'features/Moderate';
import { UITabs } from 'features/UI';
import { useMemo } from 'react';
import { SERVICE_ROLE_PREFIX } from '../../../Service/Service.model';
import { IStation } from '../../Station.interface';
import { StationTabTypes } from '../../Station.model';
import { StationBasicForm } from '../StationBasicForm/StationBasicForm';
import { StationServiceTabs } from '../StationServiceTabs/StationServiceTabs';

export function StationForm({ model, onRemove, onFinish, status, ...props }: IModerateFormComponentProps<IStation>) {
  const roles = useAuthRoles(SERVICE_ROLE_PREFIX);

  const tabs = useMemo((): TabsProps['items'] => [
    {
      key: StationTabTypes.BasicForm,
      label: 'Основные',
      children: (
        <StationBasicForm
          model={model}
          onRemove={onRemove}
          onFinish={onFinish}
          status={status}
          {...props}
        />
      ),
    },
    {
      key: StationTabTypes.ServiceTable,
      label: 'Услуги',
      disabled: !model || roles.length === 0 || status !== ModerateStatusTypes.Published,
      children: (
        <StationServiceTabs roles={roles} />
      ),
    },
  ], [model, onFinish, onRemove, props, roles, status]);

  return (
    <UITabs defaultActiveKey={StationTabTypes.BasicForm} items={tabs} />
  );
}
