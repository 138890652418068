import { Form } from 'antd';
import { FormCommonError } from 'features/Form';
import { IModerateFormComponentProps, ModerateFormDraft } from 'features/Moderate';
import { useCallback } from 'react';
import { createSlug } from 'utils/string';
import { useDirectorateIsAdmin } from '../../../Directorate/Directorate.hook';
import { IStation } from '../../Station.interface';

export function StationBasicForm({ model, onRemove, onFinish, ...props }: IModerateFormComponentProps<IStation>) {
  const [form] = Form.useForm<IStation>();
  const isDirectorateAdmin = useDirectorateIsAdmin(model?.directorate_slug);

  // serialize values
  const handleFinish = useCallback(async (values: IStation, isStartModeration: boolean) => {
    // calculate slug
    const slug = model?.slug || createSlug(values.info.name)
      .replace('vokzal', '')
      .replace('--', '-');

    try {
      await onFinish?.({
        ...model,
        ...values,
        slug,
        info: {
          ...model?.info,
          ...values.info,
        },
      }, isStartModeration);
    } catch (e) {
      console.error('form error', e);
      throw new FormCommonError();
    }
  }, [model, onFinish]);

  return (
    <ModerateFormDraft<IStation>
      form={form}
      model={model}
      onRemove={isDirectorateAdmin && model && onRemove}
      onFinish={handleFinish}
      saveLabel="Сохранить вокзал"
      {...props}
    />
  );
}
