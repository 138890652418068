import { Flex, Typography } from 'antd';
import { Spinner } from 'features/UI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStationListWithRoles } from '../../../Station/Station.hooks';
import { useLazyStationServiceListLoadQuery } from '../../../StationService/StationService.api';
import { stationSortByName } from '../../../Station/Station.model';
import { IServiceBase, ServiceType } from '../../Service.interface';

export function ServiceStationList({ serviceId, serviceType }: {
  serviceId: IServiceBase['id'];
  serviceType: ServiceType;
}) {
  const { data = [], isLoading } = useStationListWithRoles();

  const [stationServiceList, setStationServiceList] = useState([]);
  const [isServiceListLoading, setIsServiceListLoading] = useState(true);
  const [stationListLoad] = useLazyStationServiceListLoadQuery();

  const handleStationListLoad = useCallback(async () => {
    try {
      if (!serviceId) {
        return;
      }
      const result = await stationListLoad({
        service_id: serviceId,
        service_type: serviceType,
      }).unwrap();
      setStationServiceList(result);
    } finally {
      setIsServiceListLoading(false);
    }
  }, [serviceId, stationListLoad, serviceType]);

  const filtered = useMemo(() => {
    const idList = stationServiceList.map((item) => item.station_id);
    return data
      .filter((item) => idList.includes(item.id))
      .slice()
      .sort(stationSortByName);
  }, [data, stationServiceList]);

  useEffect(() => {
    handleStationListLoad();
  }, [handleStationListLoad]);

  if (isServiceListLoading || isLoading) {
    return <Spinner />;
  }

  return (
    <Flex gap={8} vertical>
      {filtered.length > 0
        ? filtered.map((item) => (
          <Link key={item.id} to={`/stations/${item.slug}/published`}>{item.info.name}</Link>
        ))
        : <Typography.Text italic>Не присутствует на вокзалах</Typography.Text>}
    </Flex>
  );
}
